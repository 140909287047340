<template>
    <!-- 制造行业 -->
    <div>
        <el-row>
            <el-col :span="24">
                <img class="medical-bg" src="../../img/solution/makingSolution/making_bg.png">
            </el-col>
            <el-col :span="24" class="solution-title">
                <el-col :span="4" :sm="10" :xs="10" class="medicalSolution">制造行业</el-col>
                <el-col :span="17" class="center-el-col"><div class="solution-line"></div></el-col>
                <el-col :span="14" class="center-el-col">
                    <p class="text-center" style="color: #666666;">某展示柜有限公司是一个从开发，设计，制造，销售，到运输全方位的一个专业的展示柜有限公司，在SOP流程中的涉及到大量的文件审批、流转和提醒等需求。</p>
                </el-col>
                <el-col :span="14" class="center-el-col">
                    <div class="title-end">
                        <div class="solution-line2"></div>
                        <div>客户背景</div>
                    </div>
                </el-col>
            </el-col>
            <el-col :span="24" class="solution-pain">
                <el-col :span="4" :sm="10" :xs="10" class="medicalSolution">行业痛点</el-col>
                <div class="pain-cards">
                    <div class="pain-card">
                        <div><img src="../../img/solution/makingSolution/icon1.png"></div>
                        <div class="text-center">文件版本混乱，容易出错</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">各个部门之间的文件流转可能还是习惯用邮件、微信、QQ等方式进行传递，版本管理混乱，容易出错。</div>
                    </div>
                    <div class="pain-card">
                        <div><img src="../../img/solution/makingSolution/icon2.png"></div>
                        <div class="text-center">文档查找效率低</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">传统的共享文件夹存储文件的方式，只能检索文件名字，而且检索速度非常慢，很不方便。</div>
                    </div>
                    <div class="pain-card pain-card1">
                        <div><img src="../../img/solution/makingSolution/icon3.png"></div>
                        <div class="text-center">文档管理和流转不便</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">制造企业急需一个好的文档管理系统打通各个部门之间文件流转和管理环节。</div>
                    </div>
                    <div class="pain-card pain-card1">
                        <div><img src="../../img/solution/makingSolution/icon4.png"></div>
                        <div class="text-center">文件误删 恶意删除</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">人为误删或者恶意删除文件，对于传统共享文件夹存储的方式是灾难性的，如果没有备份则无法找回和恢复重要文件。</div>
                    </div>
                </div>
            </el-col>
            <el-col :span="24">
                <el-col :span="4" :sm="10" :xs="12" class="medicalSolution">伊登云解决方案</el-col>
                <div class="eden-solution-plate">
                    <p class="eden-solution-title">>>办公系统无缝对接轻松审批</p>
                    <p class="eden-solution-content">对接企业办公系统，自定义系流程发起文件的审批，采用新的管理模式，让人找人变为事找人。</p>

                    <p class="eden-solution-title">>>严格的权限和版本控制</p>
                    <p class="eden-solution-content">管理员可以根据需要对员工设置文件夹的访问权限；文件版本管理功能，极大的方便了生产制造业可有效地对重要图纸文件的版本管理。</p>

                    <p class="eden-solution-title">>>全文检索以及误删回复</p>
                    <p class="eden-solution-content">支持文本类文件的全文检索，并且支持用户对文件和文件夹进线自定义标签，该标签同样支持全文检索，让用户更高效的查找文件。</p>

                    <p class="eden-solution-title">>>知识分类轻松管理</p>
                    <p class="eden-solution-content">文件根据需要分类，对特殊的分档可设置标签识别，轻松查找和整理文件。</p>

                    <p class="eden-solution-title">>>知识分类轻松管理</p>
                    <p class="eden-solution-content">支持自动编号，让集中管理的生产资料批号按需要排列序号，减少了手动输入整理的时间，使大量资料管理起来更加便捷。</p>

                    <p class="eden-solution-title">>>文件流程化管理</p>
                    <p class="eden-solution-content">实现GMP文件从设计、起草、审核、发放、培训、执行、修订、变更、归档、回顾的全生命周期管控，协助企业轻松通过GMP/FDA认证。</p>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: "makingSolution",
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
    .medical-bg {
        width: 100%;
    }

    .center-el-col {
        margin: auto;
        float: unset;
    }

    .solution-line {
        border: 1px solid #E5E5E5;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .solution-line2 {
        border: 1px solid #666666;
        width: 2rem;
        margin-right: 1rem;
        margin-top: 0.7rem;
        height: 0px;
    }

    .solution-blue-line {
        width: 48px;
        height: 6px;
        background: #2974E4;
    }

    .medicalSolution {
        margin: auto;
        float: unset;
        font-size: 40px;
        font-weight: 400;
        line-height: 45px;
        color: #3764D7;
        text-align: center;
      width: auto;
    }

    .solution-title {
        padding: 3rem 0px 1.5rem 0px;
    }

    .title-end {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .solution-pain {
        margin-top: 2rem;
        margin-bottom: 5rem;
    }

    .pain-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pain-cards {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2.5rem;
        width: 100%;
        max-width: 1110px;
        margin: 2rem auto;
    }

    .pain-card {
        max-width: 33rem;
        text-align: center;
        padding: 0.75rem;
        border-right: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
    }

    .pain-card1 {
        border-bottom: 0px;
    }

    .pain-content {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 25px;
    }

    .eden-solution-plate {
        margin: 2rem 0rem;
        max-width: 1200px;
        width: 100%;
        margin: auto;
        padding: 0 5px;
    }

    .eden-solution-title {
        color: #333333;
        font-size: 18px;
        font-weight: bold
    }

    .eden-solution-content {
        color: #333333;
        font-size: 14px;
    }

    * {
        font-size: 16px
    }

    @media screen and (min-width: 1200px){
        .pain-cards .pain-card:nth-child(2n) {
            border-right: 0px;
        }
    }

    @media screen and (max-width: 1200px){
        .pain-cards {
            max-width: 400px;
        }

        .eden-solution-plate {
            max-width: 800px;
        }

        .pain-card {
            border-right: 0px;
        }

        .pain-cards .pain-card:nth-child(3) {
            border-bottom: 1px solid #dee2e6;
        }
    }

    @media screen and (max-width: 800px){
        .pain-cards {
            max-width: 400px;
        }

        .eden-solution-plate {
            max-width: 400px;
        }

        .pain-card {
            border-right: 0px;
        }

        .pain-cards .pain-card:nth-child(3) {
            border-bottom: 1px solid #dee2e6;
        }
    }
</style>