import { render, staticRenderFns } from "./makingSolution.vue?vue&type=template&id=29457e5a&scoped=true&"
import script from "./makingSolution.vue?vue&type=script&lang=js&"
export * from "./makingSolution.vue?vue&type=script&lang=js&"
import style0 from "./makingSolution.vue?vue&type=style&index=0&id=29457e5a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29457e5a",
  null
  
)

export default component.exports